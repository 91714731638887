import React from 'react';


export default function AffTable() {
  return (
    <div className="max-w-4xl mx-auto mt-4 mb-8">
      <div className="flex flex-wrap md:flex-nowrap mb-5 pb-4 border-b-2 border-gray-400">
        <div className="flex-auto flex-shrink-0 md:flex-shrink text-xl w-full md:w-44 md:flex-grow-0 pr-5">LuckyStar Casino</div>
        <div className="flex-auto md:flex-grow text-lg">Bonus: 150% up to $1 000 + 150 Free Spins</div>
        <div className="flex-none w-20 text-xl pl-5"><a className="text-accent1" href="https://www.luckystar.io/">Visit</a></div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap mb-5 pb-4 border-b-2 border-gray-400">
        <div className="flex-auto flex-shrink-0 md:flex-shrink text-xl w-full md:w-44 md:flex-grow-0 pr-5">Spacelilly</div>
        <div className="flex-auto md:flex-grow text-lg">Bonus: 200% up to 1000$ + 200 Free Spins</div>
        <div className="flex-none w-20 text-xl pl-5"><a className="text-accent1" href="https://www.spacelilly.com/">Visit</a></div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap mb-5 pb-4 border-b-2 border-gray-400">
        <div className="flex-auto flex-shrink-0 md:flex-shrink text-xl w-full md:w-44 md:flex-grow-0 pr-5">Betbtc</div>
        <div className="flex-auto md:flex-grow text-lg">Bonus: 150% UP TO 1000€ AND no wagering free spins</div>
        <div className="flex-none w-20 text-xl pl-5"><a className="text-accent1" href="https://www.betbtc.io/">Visit</a></div>
      </div>
    </div>
  );
};
